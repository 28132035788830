import DflowLight from "../assets/dsls-logos/dflow_L.png";
import DflowDark from "../assets/dsls-logos/dflow_D.png";
import {
	SynonymExample,
	ExternalServiceExample,
	GlobalSlotExample,
	IntentExample,
	EventExample,
	DialogueExample,
	ConnectorExample,
	AccessControlExample,
	EntityExample,
} from "./dflow/insertables.js";

import { getDocumentation } from "./dflow/documentation.js";

/**
 * Retrieves the constants for the DFlow domain-specific language.
 * @returns {Promise<Object>} The constants object containing various properties related to DFlow.
 */
export const getDflowConstants = async () => {
	const constants = {
		enabled: true,
		_id: "dflow",
		name: "DFlow",
		short: "dflow",
		description: "A Domain-Specific Language for Task-based dialogue flows suitable for Virtual Assistants in smart environments. It can generate complete Rasa models.",
		logoUrl: DflowLight,
		logoUrlDark: DflowDark,
		insertableEntities: {
			Entitity: EntityExample,
			Synonyms: SynonymExample,
			EService: ExternalServiceExample,
			GlobalSlot: GlobalSlotExample,
			Intent: IntentExample,
			Event: EventExample,
			Dialogue: DialogueExample,
			Connector: ConnectorExample,
			AccessControl: AccessControlExample,
		},
		shortDescription: await getDocumentation(),
		documentation: await getDocumentation(),
		subtypes: [],
		hasValidation: true,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: true,
		supportsDeployment: false,
		generations: ["Default"],
		generationsOutputMap: {
			Default: "file",
		},
		generationLanguage: "python",
		keywords: ["triggers", "Intent", "end", "entities", "Entity", "synonyms", "Synonym", "eservices", "EServiceHTTP", "gslots", "Event", "dialogues", "Dialogue", "Form", "ActionGroup", "connectors", "Connector", "access_controls", "Roles", "Users", "Policy", "Path", "Authentication"],
		llmSupport: true,
		tags: ["Chatbot", "Textual"],
	};
	return constants;
};
