import GoaldslLight from "../assets/dsls-logos/goaldsl_L.png";
import GoaldslDark from "../assets/dsls-logos/goaldsl_D.png";

/**
 * Retrieves the constants for the GoalDSL.
 * @returns {Promise<Object>} The constants object.
 */
export const getGoaldslConstants = async () => {
	const constants = {
		enabled: true,
		_id: "goaldsl",
		name: "GoalDSL",
		short: "goaldsl",
		description: "Domain-Specific Language for evaluation of IoT applications and system behavior, based on Goals.",
		logoUrl: GoaldslLight,
		logoUrlDark: GoaldslDark,
		insertableEntities: {},
		shortDescription: "",
		documentation: "",
		subtypes: [],
		hasValidation: true,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: true,
		generationLanguage: "python",
		supportsDeployment: false,
		generations: ["Default"],
		generationsOutputMap: {
			Default: "file",
		},
		keywords: [],
		llmSupport: false,
		tags: ["CPS", "Textual"],
	};
	return constants;
};
