import GenerosLight from "../assets/dsls-logos/generos_L.png";
import GenerosDark from "../assets/dsls-logos/generos_D.png";

/**
 * Retrieves the constants for the Generos domain specific language.
 * @returns {Promise<Object>} The constants object.
 */
export const getGenerosConstants = async () => {
	const constants = {
		enabled: false,
		_id: "generos",
		name: "Generos",
		short: "generos",
		description: "A Domain Specific Language for easy and fast generation of ROS2 packages. Create complex ROS2 systems in minutes.",
		logoUrl: GenerosLight,
		logoUrlDark: GenerosDark,
		insertableEntities: {},
		shortDescription: "",
		documentation: "",
		subtypes: [],
		hasValidation: false,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: true,
		supportsDeployment: false,
		generationLanguage: "",
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		keywords: [],
		llmSupport: false,
		tags: ["Robotics", "Textual"],
	};
	return constants;
};
