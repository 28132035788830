import { shortDescription } from "./envpop/short-description.js";
import { getDocumentation } from "./envpop/documentation.js";

import EnvpopLight from "../assets/dsls-logos/envpop_L.png";
import EnvpopDark from "../assets/dsls-logos/envpop_D.png";

/**
 * Retrieves the constants for the EnvPop DSL.
 * @returns {Promise<Object>} The constants object.
 */
export const getEnvpopConstants = async () => {
	const constants = {
		enabled: true,
		_id: "envpop",
		name: "EnvPop",
		short: "envpop",
		description: "Tool for visually adding sensors, effectors and actors in EnvMaker environments.",
		logoUrl: EnvpopLight,
		logoUrlDark: EnvpopDark,
		insertableEntities: {},
		shortDescription: getDocumentation(),
		documentation: getDocumentation(),
		subtypes: [],
		hasValidation: false,
		type: "Graphical",
		canTransformTo: ["smauto"],
		mustImport: ["envmaker"],
		canImport: [],
		supportsGeneration: false,
		generationLanguage: "",
		supportsDeployment: false,
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		keywords: [],
		llmSupport: false,
		tags: ["CPS", "Graphical"],
	};
	return constants;
};
