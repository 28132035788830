import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// import DeleteIcon from "@mui/icons-material/Delete";
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import ArticleIcon from "@mui/icons-material/Article";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import VerifiedIcon from '@mui/icons-material/Verified';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { makeStyles } from '@mui/styles';

// import Popup from "../components/Popup.js";
import Search from "../components/Search.js";
import Spinner from "../components/Spinner.js";
// import Form from "../components/Form.js";
import { isFuzzyMatch, useSnackbar } from "../utils/index.js";

import { getDslsStats } from "../api/index.js";
import { getDslsConstants } from "../dsls/index.js";

const useStyles = makeStyles({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
});

const Dsls = () => {
	const { error } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState("");
	const [selectedTag, setSelectedTag] = useState("");
	const [dsls, setDsls] = useState([]);
	const [tags, setTags] = useState([]);
	const [filteredDsls, setFilteredDsls] = useState(dsls);
	const classes = useStyles();

	const navigate = useNavigate();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			const tmpTags = ["All"];

			const stats = await getDslsStats();
			const constants = await getDslsConstants();
			for (const dsl of constants) {
				dsl.modelsCount = stats.models.find((m) => m._id === dsl.short)?.count || 0;
				dsl.usersCount = stats.users.find((m) => m._id === dsl.short)?.count || 0;
				dsl.validationsCount = stats.validations.find((m) => m._id === dsl.short)?.count || 0;
				for (const tag of dsl.tags) {
					if (!tmpTags.includes(tag)) tmpTags.push(tag);
				}
			}

			// Sort tags by name
			tmpTags.sort((a, b) => a.localeCompare(b));
			setTags(tmpTags);

			// sort by enabled
			constants.sort((a, b) => {
				if (a.enabled === b.enabled) return 0;
				if (a.enabled) return -1;
				return 1;
			});

			setDsls(constants);
			setIsLoading(false);
		},
		[],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	useEffect(() => {
		setFilteredDsls(dsls.filter((us) => isFuzzyMatch(us.name, searchFilter)).filter((us) => selectedTag === "All" || us.tags.includes(selectedTag)));
	}, [searchFilter, dsls, selectedTag]);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					width="100%"
					mt={2}
					pr={2}
					mb={2}
					display="flex"
					minHeight="60px"
					borderRadius="20px"
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid
						item
						xs={7}
						ml={4}
						display="flex"
						flexDirection="row"
						alignItems="center"
						height="60px"
					>
						<Typography variant="h6" color="#ffffff">
							{"Available DSLs"}
						</Typography>
					</Grid>
					<Grid
						item
						xs={4}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						{/* Dropdown for tags */}
						<Typography color="#ffffff" sx={{ pr: 1 }}>
							{"Tag:"}
						</Typography>
						<FormControl variant="standard" sx={{ minWidth: 120, pr: 2 }}>

							<Select
								className={classes.select}
								value={selectedTag ?? "All"}
								onChange={(event) => {
									setSelectedTag(event.target.value);
								}}
							>
								{
									tags.map((e, ind) => (
										<MenuItem key={ind} value={e}>{e}</MenuItem>
									))
								}
							</Select>
						</FormControl>

						<Search value={searchFilter} onChange={(event) => setSearchFilter(event.target.value)} />
					</Grid>
				</Grid>
				<Grid
					container
					width="100%"
					justifyContent={filteredDsls.length === 0 ? "center" : "left"}
				>
					{filteredDsls.map((us, ind) => (
						<Grid
							key={`comp_${ind}`}
							container
							item
							xs={2.85}
							p={1}
							m={1}
							minHeight="60px"
							borderRadius="20px"
							alignItems="center"
							justifyContent="flex-start"
							elevation={3}
							sx={{
								background: us.enabled ? "#082743" : "#2c2c2c",
								boxShadow: "10px 3px 10px -5px #262835",
								flexDirection: "column",
								cursor: "pointer",
							}}
							onClick={() => {
								if (us.enabled) navigate(`/dsls/${us.short}`);
								else error("This DSL is not enabled yet!");
							}}
						>
							<Grid
								item
								container
								// m={1}
								flex={1}
								width="100%"
								justifyContent="flex-start"
							>
								<Grid item alignSelf="flex-end">
									{/* Add tags */}
									{us.tags.map((tag, ind2) => (
										<Chip
											key={ind2}
											label={tag}
											color="primary"
											sx={{
												mr: 0.5,
											}}
										/>
									))}
								</Grid>
							</Grid>
							<Grid
								item
								sx={{
									background: us.logoUrl ? "" : "white",
									borderRadius: 10,
									flex: 1,
									pt: 1,
									px: 1,
								}}
							>
								{us.logoUrl && (
									<img
										src={us.logoUrl}
										alt=""
										style={{
											maxHeight: "70px",
											borderRadius: "10px",
										}}
									/>
								)}
								{!us.logoUrl && <ImageNotSupportedIcon color="black" fontSize="large" />}
							</Grid>
							<Grid
								item
								sx={{
									flex: 1,
									m: 1,
								}}
							>
								<Typography variant="caption" color="#ffffff" display="block">
									{us.description}
								</Typography>
							</Grid>

							<Grid
								item
								container
								m={1}
								flex={1}
								width="100%"
								justifyContent="flex-end"
							>

								<Grid item alignSelf="flex-end" mr={us.hasValidation ? 3 : 0}>
									<Tooltip title="Total users involved">
										<Badge badgeContent={us.usersCount} color="info" sx={{ marginRight: 3 }} max={999_999}>
											<PeopleAltIcon color="secondary" />
										</Badge>
									</Tooltip>
									<Tooltip title="Total models created">
										<Badge badgeContent={us.modelsCount} color="info" sx={{ marginRight: 3 }} max={999_999}>
											<TextSnippetIcon color="secondary" />
										</Badge>
									</Tooltip>
									{us.hasValidation && (
										<Tooltip title="Total validations performed">
											<Badge showZero badgeContent={us.validationsCount} color="info" max={999_999} sx={{ marginRight: 1 }}>
												<VerifiedIcon color="secondary" />
											</Badge>
										</Tooltip>
									)}
								</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
		</>
	);
};

export default memo(Dsls);
